.center {
    text-align: center;
}
.menuSearch {
    width: 350px;
}
.active, item:hover {
    background-color: #888888;
}
.country {
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
}
.state {
    cursor: pointer;
    padding: 0 12px;
    overflow: hidden;
    background-color: #AAAAAA;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.city {
    cursor: pointer;
    padding: 0 12px;
    overflow: hidden;
    background-color: #BBBBBB;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.location {
    padding: 0 12px;
    overflow: hidden;
    background-color: #CCCCCC;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.visible {
    display: block;
}
.hidden {
    display: none;
}
.searchInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
}