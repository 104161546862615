.invisible {
    display: none;
    visibility: hidden;
}

.container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
}

.btn {
    display: inline-block;
    padding: 8px 16px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}