* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    overflow: hidden;
}

.container {
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.mapSection {
    position: relative;
}

.mapLink {
    text-decoration: none;
    color: black;
}

.mapImage {
    width: 100%;
    max-width: 600px;
    height: auto;
    transition: transform 0.3s ease;
}

.mapLink:hover .mapImage {
    transform: scale(1.1);
}

.mapCaption {
    font-size: 1.6rem;
    margin-top: 10px;
    text-align: center;
    color: black;
}

.links {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.linkItemLeft {
    position: fixed;
    bottom: 20px;
    left: 20px;
}

.linkItemRight {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.link {
    text-decoration: none;
    color: black;
}

.linkImage {
    width: 100px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.linkImage:hover {
    transform: scale(1.1);
}

.imageCaption {
    font-size: 1rem;
    margin-top: 5px;
    text-align: center;
    color: black;
}