
.filterButtonOpen {
    left: 0px;
    cursor: pointer;
    width: 20px;
    background-color: rgb(67, 86, 103);
    opacity: 0.5;
    border-radius: 0px 3px 3px 0px;
    color: rgb(255, 255, 255);
    writing-mode: tb-rl;
    border-bottom: medium none;
    display: block;
    outline: none medium;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    padding: 27px 5px;
    text-decoration: none;
    font-size: 14px;
}
.filterButtonClosed {
    left: 0px;
    cursor: pointer;
    width: 20px;
    background-color: rgb(67, 86, 103);
    opacity: 0.5;
    border-radius: 0px 3px 3px 0px;
    color: rgb(255, 255, 255);
    writing-mode: tb-rl;
    border-bottom: medium none;
    display: block;
    outline: none medium;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    padding: 27px 5px;
    text-decoration: none;
    font-size: 14px;
}
.filterControlOpen {
    left: 350px;
    top: 20%;
    position: relative;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-color: rgb(146, 146, 146);
    border-radius: 5px;
    border-style: solid;
    border-width: 0px;
    line-height: 1;
    position: fixed;
    z-index: 10000;
    box-shadow: rgb(153, 153, 153) 0px 2px 5px 0px;
    width: 0px;
    height: auto;
    padding: 0px;
}
.filterControlClosed {
    left: 0px;
    top: 20%;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-color: rgb(146, 146, 146);
    border-radius: 5px;
    border-style: solid;
    border-width: 0px;
    line-height: 1;
    position: fixed;
    z-index: 10000;
    box-shadow: rgb(153, 153, 153) 0px 2px 5px 0px;
    width: 0px;
    height: auto;
    padding: 0px;
}