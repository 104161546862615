body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
/* AboutMe.css */
.about-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-title {
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.about-content {
    font-family: 'Georgia', serif;
    font-size: 18px;
    line-height: 1.6;
    color: #666;
    text-align: justify;
}

.about-content p {
    margin-bottom: 15px;
}

.Stats_invisible__3VoKo {
    display: none;
    visibility: hidden;
}

.Stats_container__21tp5 {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
}

.Stats_btn__f2CEP {
    display: inline-block;
    padding: 8px 16px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    height: 100%;
    overflow: hidden;
}

.Home_container__vml-b {
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Home_title__1ThSC {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.Home_mapSection__3a_Uk {
    position: relative;
}

.Home_mapLink__2mZwx {
    text-decoration: none;
    color: black;
}

.Home_mapImage__2SDhp {
    width: 100%;
    max-width: 600px;
    height: auto;
    transition: transform 0.3s ease;
}

.Home_mapLink__2mZwx:hover .Home_mapImage__2SDhp {
    transform: scale(1.1);
}

.Home_mapCaption__3ES6a {
    font-size: 1.6rem;
    margin-top: 10px;
    text-align: center;
    color: black;
}

.Home_links__3z4Jo {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}

.Home_linkItemLeft__3dRvI {
    position: fixed;
    bottom: 20px;
    left: 20px;
}

.Home_linkItemRight__7prg5 {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.Home_link__YlKoC {
    text-decoration: none;
    color: black;
}

.Home_linkImage__1JGji {
    width: 100px;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.Home_linkImage__1JGji:hover {
    transform: scale(1.1);
}

.Home_imageCaption__2ZriN {
    font-size: 1rem;
    margin-top: 5px;
    text-align: center;
    color: black;
}
.Checkin_image-slideshow__2wtXS {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Checkin_image-slideshow__2wtXS img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
}

.Checkin_image-slideshow__2wtXS p {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: #333;
    margin: 20px 0;
}

.Checkin_image-slideshow__2wtXS button {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.Checkin_image-slideshow__2wtXS button:hover {
    background-color: #444;
}

.Checkin_image-slideshow__2wtXS li {
    list-style: none;
    margin: 10px 0;
}

.Checkin_image-slideshow__2wtXS li button {
    font-size: 14px;
    color: #333;
    background-color: transparent;
    border: 2px solid #333;
    border-radius: 4px;
    padding: 8px 12px;
}

.Checkin_image-slideshow__2wtXS li button:hover {
    background-color: #333;
    color: #fff;
}

.Checkin_image-slide__2Vz86 {
    position: absolute;
    width: 100%;
}

.Checkin_visible__3eom8 {
    display: flex;
}

.Checkin_hidden__2bFvT {
    display: none;
}
.SideBar_sidebar__1TrBE {
    width: 350px;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f1f1f1;
}
.SideBar_sidebarYes__1L7ZU {
    display: block;
}
.SideBar_sidebarNo__rHXBU {
    display: none;
}
.SideBar_center__3PX4H {
    text-align: center;
    margin: 10px 0;
}
.SideBar_hidden__3So1p {
    display: none;
}
.SideBar_show__1P86I {
    display: block;
}
.SideBar_nav__2MnfB{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    -webkit-user-select: none;
            user-select: none;
    transition: background 0.4s, padding-left 0.2s;
    padding-left: 0;
    background: #CCCCCC;
}
.SideBar_navspan__rKgsF{
    padding-left: 0px;
    position: relative;
}
.SideBar_checked__1G2Cm{
    border-bottom: 2px solid #777777;
    background: #777777;
    cursor: default;
}
.SideBar_tabsel__1_3pM{
    background: #777777;
}
.SideBar_layout__1EX_L{
    display: grid;
    height: auto;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr 1fr;
}
.SideBar_sidebar__1TrBE select {
    width: 100%;
    height: 150px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.SideBar_sidebar__1TrBE label {
    display: block;
    margin: 5px 0;
}
.SideBar_selectAllContainer__1nuwo {
    background-color: #777777; /* Matches the background color */
    padding: 10px; /* Adjust padding as needed */
    border-radius: 5px; /* Optional, for rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
}

.SideBar_selectAllCheckbox__3A5nE {
    margin-right: 5px;
}

.SideBar_selectAllLabel__52kWs {
    margin: 0;
}
.BeerFilter_center__Axyaa {
    text-align: center;
}
.BeerFilter_menuSearch__DyMDR {
    width: 350px;
}
.BeerFilter_brewery__lTDfs {
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 14px;
}
.BeerFilter_active__22g_n, item:hover {
    background-color: #888888;
}
.BeerFilter_beer__1pqMa {
    padding: 0 12px;
    overflow: hidden;
    background-color: #AAAAAA;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.BeerFilter_beersVisible__3eIA1 {
    display: block;
}
.BeerFilter_beersHidden__3rUqi {
    display: none;
}
.BeerFilter_searchInput__3KSqC {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.LocationFilter_center__24Nut {
    text-align: center;
}
.LocationFilter_menuSearch__1bsDY {
    width: 350px;
}
.LocationFilter_active__2APkd, item:hover {
    background-color: #888888;
}
.LocationFilter_country__3SXK7 {
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
}
.LocationFilter_state__13aAN {
    cursor: pointer;
    padding: 0 12px;
    overflow: hidden;
    background-color: #AAAAAA;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.LocationFilter_city__3qxOO {
    cursor: pointer;
    padding: 0 12px;
    overflow: hidden;
    background-color: #BBBBBB;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.LocationFilter_location__10Fzv {
    padding: 0 12px;
    overflow: hidden;
    background-color: #CCCCCC;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.LocationFilter_visible__39muY {
    display: block;
}
.LocationFilter_hidden__29D4p {
    display: none;
}
.LocationFilter_searchInput__YoxeX {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.FilterButton_filterButtonOpen__33740 {
    left: 0px;
    cursor: pointer;
    width: 20px;
    background-color: rgb(67, 86, 103);
    opacity: 0.5;
    border-radius: 0px 3px 3px 0px;
    color: rgb(255, 255, 255);
    writing-mode: tb-rl;
    border-bottom: medium none;
    display: block;
    outline: none medium;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    padding: 27px 5px;
    text-decoration: none;
    font-size: 14px;
}
.FilterButton_filterButtonClosed__3AW4h {
    left: 0px;
    cursor: pointer;
    width: 20px;
    background-color: rgb(67, 86, 103);
    opacity: 0.5;
    border-radius: 0px 3px 3px 0px;
    color: rgb(255, 255, 255);
    writing-mode: tb-rl;
    border-bottom: medium none;
    display: block;
    outline: none medium;
    position: absolute;
    white-space: nowrap;
    text-align: center;
    padding: 27px 5px;
    text-decoration: none;
    font-size: 14px;
}
.FilterButton_filterControlOpen__mz8xS {
    left: 350px;
    top: 20%;
    position: relative;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-color: rgb(146, 146, 146);
    border-radius: 5px;
    border-style: solid;
    border-width: 0px;
    line-height: 1;
    position: fixed;
    z-index: 10000;
    box-shadow: rgb(153, 153, 153) 0px 2px 5px 0px;
    width: 0px;
    height: auto;
    padding: 0px;
}
.FilterButton_filterControlClosed__3wXW- {
    left: 0px;
    top: 20%;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-color: rgb(146, 146, 146);
    border-radius: 5px;
    border-style: solid;
    border-width: 0px;
    line-height: 1;
    position: fixed;
    z-index: 10000;
    box-shadow: rgb(153, 153, 153) 0px 2px 5px 0px;
    width: 0px;
    height: auto;
    padding: 0px;
}
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
    color: #333;
}

.Records {
    height: 100vh;
    overflow-y: auto;
}

header {
    background: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
}

header h1 {
    margin: 0;
    font-size: 2rem;
}

.search-controls {
    display: flex;
    justify-content: space-between;
    margin: 1rem 2rem;
}

.search-controls input {
    flex: 1 1;
    margin-right: 1rem;
    padding: 0.5rem;
    font-size: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.search-controls select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
    padding: 1rem 2rem;
}

.card {
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card img {
    width: 100%;
    height: auto;
}

.card p {
    margin: 0.5rem;
    font-size: 1rem;
    text-align: center;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    overflow: auto;
    max-height: 80%;
}

.modal-content img {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto;
}

.modal-content h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
}

.modal-content p {
    margin: 0.5rem 0;
}

.modal .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
}

.now-playing-popup {
    position: fixed;
    top: 10%;
    right: 10%;
    background: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.3s ease;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.play-button {
    background: none;
    border: none;
    color: #00A6ED;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 10px;
}
.play-button:hover {
    color: #1ed760;
}
button.play-button:disabled {
    color: gray;
    cursor: not-allowed;
}
button.play-button[title] {
    position: relative;
}

.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-content {
    position: relative;
    width: 80%;
    max-width: 800px;
}

.close-video {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fff;
    border: none;
    font-size: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.now-playing-popup {
    position: fixed;
    top: 25px;
    right: 10px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 400px;
}

.now-playing-popup img {
    max-width: 100%;
    border-radius: 8px;
}

.now-playing-popup .details {
    margin-top: 10px;
    text-align: center;
}

.now-playing-popup .play-button {
    margin-top: 10px;
    background-color: #00A6ED;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}

.details {
    flex-grow: 1;
    text-align: left;
    margin-left: 10px;
}

.search-controls {
    display: flex;
    align-items: center;
}

.play-button {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    padding: 5px;
}

.equalizer {
    display: flex;
    align-items: flex-end;
    width: 30px;
    height: 20px;
    margin-left: 10px;
}

.bar {
    width: 4px;
    background: white;
    margin: 0 2px;
    height: 4px;
    transition: height 0.2s ease-in-out;
}

.active .bar1 { animation: equalizerAnim 1s infinite alternate ease-in-out; }
.active .bar2 { animation: equalizerAnim 1s infinite 0.2s alternate ease-in-out; }
.active .bar3 { animation: equalizerAnim 1s infinite 0.4s alternate ease-in-out; }
.active .bar4 { animation: equalizerAnim 1s infinite 0.6s alternate ease-in-out; }

@keyframes equalizerAnim {
    0% { height: 4px; }
    100% { height: 16px; }
}

