/* AboutMe.css */
.about-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-title {
    font-family: 'Arial', sans-serif;
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.about-content {
    font-family: 'Georgia', serif;
    font-size: 18px;
    line-height: 1.6;
    color: #666;
    text-align: justify;
}

.about-content p {
    margin-bottom: 15px;
}
