.image-slideshow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-slideshow img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
}

.image-slideshow p {
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: #333;
    margin: 20px 0;
}

.image-slideshow button {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.image-slideshow button:hover {
    background-color: #444;
}

.image-slideshow li {
    list-style: none;
    margin: 10px 0;
}

.image-slideshow li button {
    font-size: 14px;
    color: #333;
    background-color: transparent;
    border: 2px solid #333;
    border-radius: 4px;
    padding: 8px 12px;
}

.image-slideshow li button:hover {
    background-color: #333;
    color: #fff;
}

.image-slide {
    position: absolute;
    width: 100%;
}

.visible {
    display: flex;
}

.hidden {
    display: none;
}