.sidebar {
    width: 350px;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f1f1f1;
}
.sidebarYes {
    display: block;
}
.sidebarNo {
    display: none;
}
.center {
    text-align: center;
    margin: 10px 0;
}
.hidden {
    display: none;
}
.show {
    display: block;
}
.nav{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    user-select: none;
    transition: background 0.4s, padding-left 0.2s;
    padding-left: 0;
    background: #CCCCCC;
}
.navspan{
    padding-left: 0px;
    position: relative;
}
.checked{
    border-bottom: 2px solid #777777;
    background: #777777;
    cursor: default;
}
.tabsel{
    background: #777777;
}
.layout{
    display: grid;
    height: auto;
    width: 100%;
    overflow: hidden;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr 1fr;
}
.sidebar select {
    width: 100%;
    height: 150px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}
.sidebar label {
    display: block;
    margin: 5px 0;
}
.selectAllContainer {
    background-color: #777777; /* Matches the background color */
    padding: 10px; /* Adjust padding as needed */
    border-radius: 5px; /* Optional, for rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectAllCheckbox {
    margin-right: 5px;
}

.selectAllLabel {
    margin: 0;
}