body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
    color: #333;
}

.Records {
    height: 100vh;
    overflow-y: auto;
}

header {
    background: #333;
    color: #fff;
    padding: 1rem;
    text-align: center;
}

header h1 {
    margin: 0;
    font-size: 2rem;
}

.search-controls {
    display: flex;
    justify-content: space-between;
    margin: 1rem 2rem;
}

.search-controls input {
    flex: 1;
    margin-right: 1rem;
    padding: 0.5rem;
    font-size: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.search-controls select {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    padding: 1rem 2rem;
}

.card {
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card img {
    width: 100%;
    height: auto;
}

.card p {
    margin: 0.5rem;
    font-size: 1rem;
    text-align: center;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    text-align: left;
    overflow: auto;
    max-height: 80%;
}

.modal-content img {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto;
}

.modal-content h2 {
    font-size: 1.5rem;
    margin-top: 1rem;
}

.modal-content p {
    margin: 0.5rem 0;
}

.modal .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
}

.now-playing-popup {
    position: fixed;
    top: 10%;
    right: 10%;
    background: #333;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.3s ease;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.play-button {
    background: none;
    border: none;
    color: #00A6ED;
    font-size: 1rem;
    cursor: pointer;
    margin-right: 10px;
}
.play-button:hover {
    color: #1ed760;
}
button.play-button:disabled {
    color: gray;
    cursor: not-allowed;
}
button.play-button[title] {
    position: relative;
}

.video-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-content {
    position: relative;
    width: 80%;
    max-width: 800px;
}

.close-video {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #fff;
    border: none;
    font-size: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}

.now-playing-popup {
    position: fixed;
    top: 25px;
    right: 10px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 400px;
}

.now-playing-popup img {
    max-width: 100%;
    border-radius: 8px;
}

.now-playing-popup .details {
    margin-top: 10px;
    text-align: center;
}

.now-playing-popup .play-button {
    margin-top: 10px;
    background-color: #00A6ED;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}

.details {
    flex-grow: 1;
    text-align: left;
    margin-left: 10px;
}

.search-controls {
    display: flex;
    align-items: center;
}

.play-button {
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    padding: 5px;
}

.equalizer {
    display: flex;
    align-items: flex-end;
    width: 30px;
    height: 20px;
    margin-left: 10px;
}

.bar {
    width: 4px;
    background: white;
    margin: 0 2px;
    height: 4px;
    transition: height 0.2s ease-in-out;
}

.active .bar1 { animation: equalizerAnim 1s infinite alternate ease-in-out; }
.active .bar2 { animation: equalizerAnim 1s infinite 0.2s alternate ease-in-out; }
.active .bar3 { animation: equalizerAnim 1s infinite 0.4s alternate ease-in-out; }
.active .bar4 { animation: equalizerAnim 1s infinite 0.6s alternate ease-in-out; }

@keyframes equalizerAnim {
    0% { height: 4px; }
    100% { height: 16px; }
}
