.center {
    text-align: center;
}
.menuSearch {
    width: 350px;
}
.brewery {
    cursor: pointer;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 14px;
}
.active, item:hover {
    background-color: #888888;
}
.beer {
    padding: 0 12px;
    overflow: hidden;
    background-color: #AAAAAA;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
    display: block;
    word-wrap: break-word;
}
.beersVisible {
    display: block;
}
.beersHidden {
    display: none;
}
.searchInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    box-sizing: border-box;
}